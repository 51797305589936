.circle {
  width: 6px;
  height: 6px;
  background-color: #0c7dbb;
  border-radius: 50%;
  margin-left: 0.5px;
  margin-top: 3px;
}

.line {
  height: 16px;
  width: 1px;
  background: #757575;
  margin-left: 3px;
  margin-top: 7px;
  margin-bottom: 7px;
  border-radius: 3px;
}

.blue {
  background: #0c7dbb;
}

.gray {
  background: #757575;
}

.mobileLine {
  height: 1px;
  width: 11.1vh;
  min-width: 90px;
  margin-top: 5.5px;
  margin-left: 6px;
  margin-right: 6px;
}
@media only screen and (max-width: 850px) and (min-width: 600px) {
  .mobileLine {
    height: 1px;
    width: 12.5vh;
    margin-top: 5.5px;
    margin-left: 6px;
    margin-right: 6px;
  }
}
.center {
  justify-content: center;
}
.grayCircle {
  background: #ffffff;
  /* Grayscale/Gray 60 */

  border: 1px solid #757575;
  width: 5px;
  height: 5px;
  margin-top: 3px;
  border-radius: 50%;
}

.current {
  background: #0c7dbb;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: -1px;
  margin-top: 1px;
}

.currentMiddle {
  width: 4px;
  height: 4px;
  position: absolute;
  border-radius: 50%;
  background: #0c7dbb;
  border: 1px solid #ffffff;
  margin-left: 2px;
  margin-top: 2px;
}

.mobileHeader {
  font-weight: 500;
  font-size: 26px;
  line-height: 36px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.stepText {
  margin-top: -1px;
  font-weight: bold;
  font-size: 14px;
}

.stepTextNext {
  margin-top: -2px;
  color: #4f4f4f;
  font-size: 14px;
}
