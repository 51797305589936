.centerForm {
  display: flex;
  align-items: center;
  justify-content: center;
}

.formLabel {
  margin-top: 20px;
  margin-bottom: 7px;
  font-weight: 700;
  font-size: 18px;
}

.yellowButton {
  background: #fbbb36;
  border-radius: 4px;
  padding-left: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 25px;
  position: absolute;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  margin-bottom: 10px;
  border-color: transparent;
}

.yellowLoadingButton {
  background: #fbbb36;
  border-radius: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: absolute;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  margin-bottom: 10px;
  border-color: transparent;
  justify-content: center;
  width: 120px;
}

@media only screen and (max-width: 600px) {
  .yellowLoadingButton {
    width: 90%;
  }
  .yellowButton {
    width: 90%;
  }
}
.onLoad {
  background: #feedc7;
}

.secondButton {
  color: #07679b;
  border: none;
  cursor: pointer;
  text-decoration-line: underline;
  background: none;
  font-size: 17px;
  padding: 0;
}
.secondButton:active,
.secondButton:hover {
  color: #19335e;
}

.arrowSecondButton {
  color: #07679b;
  font-weight: 700;
}

.arrowSecondButton:active,
.arrowSecondButton:hover {
  color: #19335e;
}
@media only screen and (max-width: 600px) {
  .secondButtonDiv {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}
.secondButtonDiv {
  margin-top: 30px;
}

.yellowButton:hover {
  background: #fbab18;
}

.blueSessionButton {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 11px;
  padding-bottom: 11px;
  border: 1px solid #07679b;
  border-radius: 4px;
  background-color: transparent;
  line-height: 24px;
  color: #07679b;
  font-weight: 700;
  cursor: pointer;
  font-size: 16px;
}

.yellowSessionButton {
  background: #fbbb36;
  border-radius: 4px;
  padding-left: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 25px;
  position: absolute;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  margin-bottom: 10px;
  border-color: transparent;
}
.inputDescription {
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 28px;
}

input {
  font-size: 16px;
}
@media only screen and (max-width: 600px) {
  .input {
    width: 100%;
  }
}
.input {
  border: 1px solid #4f4f4f;
  border-radius: 4px;
  width: 320px;
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
}
.input:focus {
  outline: none;
  border: 2.5px solid #0c7dbb;
}

.inputIcon {
  margin-left: -30px;
  margin-right: 16px;
}
.errorInput {
  border: 2.5px solid #e2231a;
  border-radius: 4px;
  width: 320px;
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
}

.errorInput:active,
.errorInput:focus {
  border: 2.5px solid #e2231a;
  outline: none;
  background-color: #ffffff;
}

.errorText {
  color: #e2231a;
  margin-top: 8px;
  font-size: 16px;
}
.row {
  display: flex;
}

.div {
  color: #1b1c1d;
}

.header {
  font-size: 32px;
  font-weight: 500;
  margin-top: 50px;
  margin-bottom: 40px;
  line-height: 42px;
}

@media only screen and (max-width: 600px) {
  .header {
    font-size: 26px;
    margin-bottom: 20px;
  }
}

.subheader {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 30px;
}
@media only screen and (max-width: 600px) {
  .subheader {
    font-size: 20px;
  }
}

.authPropertyHeader {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 20px;
}
.description {
  margin-bottom: 30px;
  line-height: 28px;
  font-size: 16px;
}

.emailDescription {
  margin-bottom: 20px;
  margin-top: 30px;
  line-height: 28px;
}

.mobileBar {
  padding-left: 15px;
}
@media only screen and (max-width: 700px) {
  .mobileBar {
    padding-left: 0px;
  }
}

.divideLine {
  width: 2px;
  min-width: 2px;
  height: auto;
  min-height: 85vh;
  background: #e6e7e8;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.footerText {
  padding-left: 15px;
}

.footerContainer {
  width: 100%;
  padding-top: 60px;
  padding-bottom: 80px;
  background-color: #f4f5f6;
}

.footerLinks {
  justify-content: space-between;
  margin-bottom: 30px;
}

.footerLinksPadding {
  margin-bottom: 20px;
}

.sessionTimeoutAlert {
  margin-left: 24px;
  margin-top: 20px;
  margin-right: 40px;
}

.sessionTimeoutDescription {
  margin-left: 24px;
  margin-right: 40px;
  line-height: 26px;
}

.sessionTimeoutDeleted {
  margin-left: 24px;
  line-height: 26px;
}

.sessionTimeoutButtons {
  justify-content: flex-start;
  margin-left: 24px;
  margin-top: 20px;
}

.sessionTimeoutOk {
  justify-content: flex-start;
  margin-left: 24px;
  margin-top: 20px;
  margin-bottom: 60px;
}

.sessionTimeoutOkButton {
  margin-right: 24px;
  width: full;
}
